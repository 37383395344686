import { z } from 'zod';
import { Address, AddressUpdate } from '../composite-types';
import {
  ComingFromType,
  CommunityCode,
  GdprStatus,
  GenderCode,
  OrganizationActivity,
  OrganizationVolume,
  UserLookingForContract,
} from '../enums';

export const UsersQuarkIndustriesHierarchy = z.object({
  code: z.string(),
  name: z.string(),
  avg_level: z.number(),
  avg_interest: z.number(),
  number_code: z.number(),
});

const useCase = OrganizationActivity.or(z.literal('OTHERS'));

export const OrganizationFormMetadata = z.union([
  z.object({
    useCases: useCase.array(),
    customActivity: z.string().trim().nullish(),
    talentVolume: OrganizationVolume,
    email: z.string().email(),
    name: z.string(),
    phone: z.string(),
    job: z.string(),
    verificationCode: z.string(),
    emailIsValid: z.boolean(),
  }),
  z.object({
    useCases: useCase.array(),
    customActivity: z.string().trim().nullish(),
    talentVolume: OrganizationVolume,
    email: z.string().email(),
    name: z.string(),
    phone: z.string(),
    job: z.string(),
  }),
  z.object({
    useCases: useCase.array(),
    customActivity: z.string().trim().nullish(),
    talentVolume: OrganizationVolume,
  }),
  z.object({
    useCases: useCase.array(),
    customActivity: z.string().trim().nullish(),
  }),
  z.object({}),
]);

export type OrganizationFormMetadataT = z.infer<
  typeof OrganizationFormMetadata
>;

export const Users = z.object({
  id: z.string().uuid(),
  first_name: z.string(),
  last_name: z.string(),
  full_name: z.string().optional(),
  initials: z.string().optional(),
  initials_avatar: z.string().optional(),
  main_color: z.string().optional(),
  avatar: z.string().nullable(),
  cv: z.string().nullable(),
  email: z.string(),
  url: z.string(),
  gender_id: GenderCode.nullable(),
  birth_year: z.number().nullable(),
  address: Address.nullable(),
  show_address: z.boolean(),

  auth_providers: z
    .string()
    .array()
    .nullish()
    .transform((providers) => providers ?? []),
  complete_url: z.string().url().nullish(),

  source: z.string().trim().max(250).nullable().optional(),
  community_code: CommunityCode,
  community_config_id: z.string().uuid(),
  community_variation: z.string().nullish(),

  coming_from: ComingFromType,
  campaign: z.string().nullable(),
  has_aura: z.boolean(),
  has_helio: z.boolean(),
  has_quark: z.boolean(),
  has_tools: z.boolean(),
  has_kick: z.boolean(),
  has_kick_experience: z.boolean(),
  has_kick_formation: z.boolean(),
  has_kick_merciii: z.boolean(),
  has_kick_vigijobs: z.boolean(),

  looking_for_contract: UserLookingForContract.nullable(),

  job: z.string().nullable(),
  gdpr_status: GdprStatus,
  gdpr_before_fifteen_days_sent: z.boolean(),
  gdpr_before_thirty_days_sent: z.boolean(),
  is_pro_email: z.boolean(),

  is_private: z.boolean(),
  phone: z.string().nullable(),
  show_phone: z.boolean(),
  has_verified_phone: z.boolean(),
  description: z.string().nullable(),
  facebook: z.string().nullable(),
  show_facebook: z.boolean(),
  linked_in: z.string().nullable(),
  show_linked_in: z.boolean(),
  twitter: z.string().nullable(),
  show_twitter: z.boolean(),
  website: z.string().nullable(),
  show_website: z.boolean(),

  // config: Configs.nullish().optional(),
  // compose_config: ComposeConfigReturns.nullish().optional(),

  can_receive_marketing_mails: z.boolean(),

  old_id: z.string().nullable(),

  quark_industries_hierarchy: UsersQuarkIndustriesHierarchy.array().optional(),
  organization_form_metadata: OrganizationFormMetadata,

  tsid_tool_onboarding_skip: z.record(
    z.string().uuid(),
    z.boolean({ coerce: true })
  ),

  created_at: z.string(),
  updated_at: z.string(),
});

export type UsersT = z.infer<typeof Users>;

export const UsersInsert = Users
  // .omit({
  //   config: true,
  //   compose_config: true,
  // })
  .partial()
  .merge(
    Users.pick({
      id: true,
      first_name: true,
      last_name: true,
      email: true,
    })
  );

export type UsersInsertT = z.infer<typeof UsersInsert>;

export const UsersUpdate = Users.omit({
  id: true,
  address: true,
  // config: true,
  // compose_config: true,
})
  .merge(
    z.object({
      address: AddressUpdate.partial(),
    })
  )
  .partial();

export type UsersUpdateT = z.infer<typeof UsersUpdate>;
